import images from '~/assets/images';
const Data = [
    {
        id: 1,
        name: 'JuJo Bin',
        background: images.b1,
        avatar: images.b1,
        content: 'Thôi...không xong rồiiii haizzzz Thôi...không xong rồiiii',
        music: 'nhạc nền - Beautiful in White',
        viewers: 904,
    },
    {
        id: 2,
        name: 'Hoang y Nhi',
        background: images.b2,
        avatar: images.hyn,
        content: 'em có hai nụ cười🍑🍑🍑',
        viewers: 750,
    },
    {
        id: 3,
        name: 'Thy Thy',
        avatar: images.vanthy,
        background: images.b3,
        content: 'Cuốn z là p đu ngay💃🏻',
        viewers: 129,
    },
    {
        id: 4,
        background: images.b4,
        avatar: 'https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/8ff7a8e134012d4c36ea2d6a0cc8d90c~c5_100x100.jpeg?x-expires=1660615200&x-signature=Pvg0fbTz2Bk4O82EeU1XOdiNNwk%3D',
        name: '๖ۣۜҪôηɠ•Ҫɦúล ๖ۣۜHòลηɠ•Ŧửツ',
        content: 'em đâu làm gì sai đâu sao bỏ em vậy😞',
        viewers: 1223,
    },
    {
        id: 5,
        name: 'Tiểu Hý',
        background: images.b5,
        avatar: images.tieuhy,
        content: 'Cái ngày mà tui còn mập nè🤣',
        viewers: 4958,
    },
    {
        id: 6,
        name: 'fan sam tổng mãi đỉnh 😝😝',
        background: images.b6,
        avatar: images.fansam,
        content: 'hello mn',
        viewers: 879,
    },
];

export default Data;

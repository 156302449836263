export const SET_USER_INFO = 'set_user_info';
export const USER_LOG_IN = 'user_log_in';
export const SET_SUGGEST_ACCOUNTS = 'set_suggest_accounts';
export const SET_FOLLOWING_ACCOUNTS = 'set_following_accounts';
export const CURRENT_USER_INFO = 'current_user_info';
export const GET_LIST_CMT = 'get_list_cmt';
export const MESSAGE_AUTHOR_LIST = 'message_author_list';
export const COMMENTS_NOW = 'comments_now';
export const SET_LANGUAGE = 'set_language';
export const SET_MODEL = 'set_model';
export const SET_VOLUME = 'set_volume';
export const SET_CURRENT_USER_VIDEO = 'set_current_user_video';
export const SET_TYPE_STATUS = 'set_type_status';
export const SET_LIST_VIDEO_WATCHING = 'set_list_video_watching';
